import { mapState, mapGetters, mapMutations } from 'vuex'
import trackDirection from './trackDirection'
import moment from 'moment'
import { trackingApi } from '@/api'
import geometryutil from 'leaflet-geometryutil'
// import { defaults } from 'lodash'

export default {
  mixins: [trackDirection],
  computed: {
    ...mapGetters('login', ['changingCompany']),
    ...mapState('reports', {
      groupOrNormalReport: (state) => state.groupOrNormalReport,
      tracksToDrawCall: (state) => state.tracksToDrawCall,
      polyline: (state) => state.track,
      showPolyline: (state) => state.showPolyline,
      removePolyline: (state) => state.removeTrack,
      trackByRow: (state) => state.trackByDate,
      imageToPrint: (state) => state.imageToPrint,
      chartLocations: (state) => state.chartLocations,
      removeChartLocation: (state) => state.removeChartLocation,
      stopsToShow: (state) => state.stopsToShow,
      colorCompany: (state) => state.colorCompany,
      tableCurrentId: (state) => state.tableCurrentId,
      closeTrek: (state) => state.closeTrek,
      directiveZoom: (state) => state.directiveZoom,
      isRemoveTrack: (state) => state.isRemoveTrack,
      isSwitchingObject: (state) => state.isSwitchingObject,
      tableType: (state) => state.tableType,
      isTrackByRowClosed: (state) => state.isTrackByRowClosed
    }),
    ...mapState('map', {
      reportsDrawnMarkers: (state) => state.reportsDrawnMarkers,
      journalMarker: (state) => state.latlon,
      isHidingSignatures: (state) => state.isHidingSignatures,
      currentRow: (state) => state.currentRow,
      tableName: (state) => state.tableName,
      columnTable: (state) => state.columnTable,
      // isStopShowing: (state) => state.isStopShowing,
      isStopShowing: (state) => state.isStopShowing,
      isAllStops: (state) => state.isAllStops,
      otechGeozone: (state) => state.otechGeozone,

      removeMarkerId: (state) => state.markerId,
      startend: (state) => state.startend,
      startendId: (state) => state.startendId,
      removeTrackId: (state) => state.removeTrackId,
      eyeTriggered: (state) => state.eyeTriggered.value,
      currentWeights: (state) => state.trackWeight
    }),
    ...mapState('measure', {
      isMeasureStarted: (state) => state.isMeasureStarted
    }),

    styles() {
      return {
        style_0: {
          color: '#55d0ff',
          weight: this.currentWeights
        },
        style_40: {
          color: '#714684',
          weight: this.currentWeights
        },
        style_80: {
          color: '#ef7f1a',
          weight: this.currentWeights
        },
        'style_-1': {
          color: 'black',
          dashArray: '1 5'
        }
      }
    },
    borderStyles() {
      return {
        style_0: {
          color: '#fff',
          weight: this.currentWeights + 2
        },
        style_40: {
          color: '#fff',
          weight: this.currentWeights + 2
        },
        style_80: {
          color: '#fff',
          weight: this.currentWeights + 2
        },
        'style_-1': {
          color: 'black',
          dashArray: '1 5'
        }
      }
    },
    shadowStyle() {
      const shadowFilter = 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16))'
      return {
        style_0: {
          color: 'gray',
          weight: this.currentWeights + 3,
          opacity: 0.4,
          filter: shadowFilter
        },
        style_40: {
          color: 'gray',
          weight: this.currentWeights + 3,
          opacity: 0.4,
          filter: shadowFilter
        },
        style_80: {
          color: 'gray',
          weight: this.currentWeights + 3,
          opacity: 0.4,
          filter: shadowFilter
        },
        'style_-1': {
          color: 'black',
          dashArray: '1 5'
        }
      }
    }
  },
  data() {
    return {
      isMarkerCentered: false,
      trackId: null,
      markerFillings: null,
      markerStops: null,
      polylines: [],
      newChartMarker: [],
      oldChartMarker: [],
      tracksToDraw: null,
      unitIdTracksToDraw: null,
      tracksToDrawAll: [],
      activeTracksbyDate: {},
      activeMarkers: {},
      activeStartends: {},
      newMarker: '',
      currentMarker: '',
      currentFlags: [],
      isReportClosed: false,
      currentTrackLayer: [],
      currentCoordinateOnMap: null,
      circle: null,
      borderLayersGroup: L.featureGroup(),
      shadowLayersGroup: L.featureGroup(),
      trackByRowGroup: L.featureGroup(),
      isAnnotationOnClick: false
    }
  },
  watch: {
    currentWeights(val) {
      if (this.fastTrack) {
        this.fastTrack.eachLayer((layer) => {
          this.updateWeight(layer, val)
        })
      }
      if (this.trackByRowGroup) {
        this.trackByRowGroup.eachLayer((layer) => {
          this.updateWeightRow(layer, val)
        })
      }
      if (this.tracksToDraw) {
        this.tracksToDraw.eachLayer((layer) => {
          this.updateWeight(layer, val)
        })
      }

      this.borderLayersGroup.eachLayer((layer) => {
        this.updateWeight(layer, val + 2)
      })

      this.shadowLayersGroup.eachLayer((layer) => {
        this.updateWeight(layer, val + 3)
      })
    },
    isTrackByRowClosed() {
      this.closeTrackByRow()
    },
    isSwitchingObject() {
      this.combinePolylines = []
    },
    isReportClosed() {
      this.removeDirectionArrow()
    },
    isRemoveTrack() {
      if (this.map.hasLayer(this.tracksToDraw)) {
        this.tracksToDrawAll.forEach((track) => {
          this.map.removeLayer(track.track)
        })

        this.resetReports()
      }
    },

    isHidingSignatures(val) {
      this.hideArrowsFromMap(val)
    },

    isAllStops(val) {
      if (val === 'all' && this.currentMarker) {
        // remove currentMarker from map
        this.currentMarker.removeFrom(this.map)
      }
    },

    colorCompany(val) {
      if (this.trackByRow && val.length) {
        this.tracksToDraw.setStyle({ color: val[0].color })
      }

      if (this.tracksToDraw && this.tableCurrentId) {
        this.tracksToDrawAll.forEach((vall, keyAllTracks) => {
          const trackTime = new Date(1970, 0, 1)
          trackTime.setSeconds(this.tracksToDrawAll[keyAllTracks].time)
          if (
            this.tableCurrentId.unit_id &&
            this.tableCurrentId.unit_id.indexOf(vall.track.options.unitId) !==
              -1
          ) {
            const currentLine = val.filter((valId) => {
              if (
                valId.from === this.tableCurrentId.from &&
                this.tableCurrentId.report.key === valId.type &&
                valId.to === this.tableCurrentId.to
              ) {
                this.tracksToDraw = vall.track
              }
              return (
                this.tableCurrentId.unit_id === valId.id &&
                this.tableCurrentId.report.key === valId.type &&
                valId.from === this.tableCurrentId.from &&
                valId.to === this.tableCurrentId.to
              )
            })[0]
            if (currentLine) {
              this.tracksToDraw.setStyle({
                color: currentLine.color
              })
            }
          }
        })
      }
    },

    changingCompany() {
      this.resetReports()
      this.$store.commit('reports/SET_REMOVE_TABLE')
    },

    imageToPrint() {
      const { map } = this
      this.$store.dispatch('reports/imageReady', map)
    },

    eyeTriggered(val) {
      if (val === 'notifications') {
        this.resetNotificationMap()
        this.$store.commit('map/EYE_TRIGGER', '')
      } else if (val === 'tracks') {
        this.resetReports()
        this.$store.commit('map/EYE_TRIGGER', '')

        // this.resetReports()
        this.resetMonitoring()
        // this.mlayer.clearLayers()
      } else if (val === 'all') {
        this.resetNotificationMap()
        this.resetReports()
        this.resetMonitoring()
        this.$store.commit('map/EYE_TRIGGER', 'hideall')
      }
    },

    removeTrackId(val) {
      console.log('removeTrackId removeTrackId')
      // const trackID = Object.keys(val)[0]
      // if (this.activeTracksbyDate && this.activeTracksbyDate[trackID]) {
      //   this.activeTracksbyDate[trackID].off(
      //     'click',
      //     this.showTrackAnnotation,
      //     this
      //   )
      //   this.activeTracksbyDate[trackID].removeFrom(this.map)
      //   delete this.activeTracksbyDate[trackID]
      // }
    },

    startendId(val) {
      const startendID = Object.keys(val)[0]
      if (this.activeStartends && this.activeStartends[startendID]) {
        this.activeStartends[startendID].removeFrom(this.map)
        delete this.activeStartends[startendID]
      }
    },

    startend(val) {
      const startendID = Object.keys(val)[0]
      const startendData = Object.values(val)[0]

      if (Object.keys(this.activeStartends).includes(startendID)) {
        this.activeStartends[startendID].removeFrom(this.map)
        delete this.activeStartends[startendID]
      }
      this.addStartEndByDate(startendID, startendData)
    },

    removeChartLocation() {
      this.CLEAR_TREK(true)
      if (
        this.newMarker instanceof L.Layer &&
        this.map.hasLayer(this.newMarker)
      ) {
        this.newMarker.removeFrom(this.map)
      }
    },

    chartLocations(newval, oldval) {
      const icon = L.icon({
        iconUrl: require('../assets/icons/row-marker.svg'),
        // iconSize: [26, 36],
        iconAnchor: [20, 36]
      })
      const newMarker = L.marker(newval, {
        icon,
        draggable: false
      })
      this.newChartMarker.push(newMarker)
      this.oldChartMarker = L.marker(oldval, {
        icon,
        draggable: false
      })
      this.newChartMarker.forEach((el) => {
        if (this.map.hasLayer(el)) {
          this.map.removeLayer(el)
          const i = this.newChartMarker.indexOf(el)
          this.newChartMarker.splice(i, 1)
        }
      })
      newMarker.addTo(this.map)
      this.map.setView(newMarker.getLatLng(), this.zoom)
      this.newMarker = newMarker
    },

    removeMarkerId(val) {
      const markerID = Object.keys(val)[0]
      if (this.activeMarkers && this.activeMarkers[markerID]) {
        this.activeMarkers[markerID].removeFrom(this.map)
        delete this.activeMarkers[markerID]
      }
    },

    currentRow(val) {
      if (!val) return

      const markerCoords = val[`_marker_latlon_${this.tableName}`]
      const markerId = Object.keys(this.journalMarker)[0]

      this.clearTrackFlags(val)
      this.closeTrackByRow()
      if (Object.keys(this.activeMarkers).includes(markerId)) {
        this.activeMarkers[markerId].removeFrom(this.map)
        delete this.activeMarkers[markerId]
      }
      const isShowAnnotationOnClick = JSON.parse(
        localStorage.getItem('isShowAnnotationOnClick')
      )
      if (this.isAllStops === 'all' && isShowAnnotationOnClick) {
        this.reportsDrawnMarkers.eachLayer((layer) => {
          const latlng = layer.getLatLng()
          if (
            latlng.lat === markerCoords[0] &&
            latlng.lng === markerCoords[1]
          ) {
            this.reportsDrawnMarkers.zoomToShowLayer(layer, () => {
              layer.openPopup()
            })
          }
        })
      } else if (val.chronology_information !== undefined) {
        this.addMarkerBydateToMap(markerId, val)
      } else if (!val.first_address) {
        this.addMarkerBydateToMap(markerId, val)
      }
    },

    polyline(val) {
      // TODO сделать так чтобы this.tableCurrentId.unit_id получали быстрее чем трек
      setTimeout(() => {
        if (this.tableType === 'analytics_units_events') return
        // init report store polylines
        this.combinePolylines = this.combinePolylines || []
        if (
          !this.combinePolylines.some(
            (p) => p.uniqueTrackId === val.uniqueTrackId
          )
        ) {
          this.combinePolylines = [...this.combinePolylines, val]
        }

        // tracksToDrawCall зачем это?
        if (!this.tracksToDrawCall) {
          let show = true
          this.zoomActive = false
          if (this.polylines.length) {
            this.polylines.forEach((item) => {
              show = !(
                item.unit_id === this.tableCurrentId.unit_id &&
                item.to === this.tableCurrentId.to &&
                item.from === this.tableCurrentId.from
              )
            })
          }

          this.polylines.push(this.tableCurrentId)
          // if (show) {
          if (!this.tracksToDraw) {
            this.tracksToDrawAll = []
          }
          if (val.track.length === 0) return

          // this.$emit('isTrackLegend', true)
          this.$emit('showLegend', {
            isShowLegend: true,
            unitName: this.tableCurrentId.unit_id
          })
          this.tracksToDraw = L.featureGroup()
          // добавляется трек на карту
          this.tracksToDraw.addTo(this.map)

          const trackLayers = this.showTrack(val.track, this.styles)
          const borderTrackLayers = this.showTrack(val.track, this.borderStyles)

          // trackStylesGroup
          const shadowLayers = this.showTrack(val.track, this.shadowStyle)
          for (let i = 0; i < trackLayers.length; i += 1) {
            const layer = trackLayers[i]
            const borderLayer = borderTrackLayers[i]
            const shadowLayer = shadowLayers[i]
            this.borderLayersGroup.addLayer(borderLayer)
            this.shadowLayersGroup.addLayer(shadowLayer)
            this.map.addLayer(shadowLayer)
            this.map.addLayer(borderLayer)
            const latlngs = layer.getLatLngs()
            this.currentTrackLayer.push(latlngs)
            this.tracksToDraw.addLayer(layer)
          }

          this.unitIdTracksToDraw = this.tracksToDraw.options.unitId || val.id

          this.tracksToDraw.on('click', (e) => {
            this.showTrackAnnotation(
              e.layer,
              this.unitIdTracksToDraw,
              e.latlng,
              val.track
            )
          })

          const paddingOptions = {
            paddingTopLeft: [0, 0],
            paddingBottomRight: [0, 310]
          }
          this.tracksToDraw.options.unitId = val.id
          this.tracksToDraw.options.uniqueTrackId = val.uniqueTrackId
          this.map.fitBounds(this.tracksToDraw.getBounds(), paddingOptions)

          this.tracksToDrawAll.push({
            track: this.tracksToDraw,
            time: val.track[0].points[0][0]
          })

          this.DIRECTIVE_ZOOM(true)
          this.lastDistance = 0
          if (this.listMarkers.monitoring) {
            this.zoomReports = true
            this.zoomMonitoring = true
            this.zoomReportsTrack = false
          } else {
            this.zoomReports = true
            this.zoomMonitoring = false
            this.zoomReportsTrack = false
          }

          // update driven arrows
          this.updatePolylines()
          // }
          setTimeout(() => {
            const canvas = document.querySelectorAll(
              '.leaflet-overlay-pane canvas'
            )
            if (canvas.length) {
              if (canvas && navigator.userAgent.indexOf('Firefox') === -1) {
                canvas[1] && canvas[1].style.zIndex === '101'
                canvas[0].style.zIndex = '100'
              } else {
                canvas[1] && canvas[1].style.zIndex === '101'
                canvas[0].style.zIndex = '100'
              }
            } else {
              console.log('canvas not found..', canvas)
            }
          }, 0)
        }
      }, 200)
    },

    removePolyline() {
      this.resetReports()

      const compareId = this.tableCurrentId
        ? `${this.tableCurrentId.unit_id}[${this.tableCurrentId.from}-${this.tableCurrentId.to}]`
        : ''
      // remove driven arrows for closed track
      if (compareId) {
        this.combinePolylines = this.combinePolylines.filter(
          (poly) => poly.uniqueTrackId !== compareId
        )
      } else {
        this.combinePolylines = []
      }

      // remove current report layer
      this.tracksToDrawAll = this.tracksToDrawAll.reduce((acc, observer) => {
        if (observer.track.options.uniqueTrackId === compareId) {
          this.map.removeLayer(observer.track)

          return acc
        }

        return [...acc, observer]
      }, [])

      // update driven arrows for open tracks
      this.updatePolylines()
    },

    trackByRow(val) {
      const itemsToClear = ['reportsTrack']
      const trackId = Object.keys(val)[0]
      const trackData = Object.values(val)[0]
      const { annData, track } = val

      // clear timeout for item before start zoom
      this.clearTimeArrows(itemsToClear)

      this.trackId = trackId
      this.DIRECTIVE_ZOOM(true)
      if (!this.directionArrows.reports) {
        this.zoomReports = false
        this.zoomReportsTrack = true

        this.map.on('moveend', (s) => {
          // clear timeout for item after zoom end
          this.clearTimeArrows(itemsToClear)
          // clear arrows from map
          this.clearDirectionsArrows(itemsToClear)

          if (
            trackData &&
            this.directiveZoom &&
            this.zoomReportsTrack &&
            val._type_chronology !== 'stays'
          ) {
            this.showDirectives(
              { track: [{ points: trackData }] },
              'reportsTrack'
            )
          }
        })
      }
      const canvas = document.querySelectorAll('.leaflet-overlay-pane canvas')

      if (canvas.length > 1) {
        if (navigator.userAgent.indexOf('Chrome') !== -1) {
          canvas[0].style.zIndex = '100'
          canvas[1].style.zIndex = '101'
        }

        if (navigator.userAgent.indexOf('Firefox') !== -1) {
          canvas[1].style.zIndex = '101'
          canvas[0].style.zIndex = '100'
        }
      }
      // if (Object.keys(this.activeTracksbyDate).includes(trackId)) {
      //   this.activeTracksbyDate[trackId].off(
      //     'click',
      //     this.showTrackAnnotation,
      //     this
      //   )
      //   // this.tracksToDraw = this.activeTracksbyDate[trackId]
      //   this.activeTracksbyDate[trackId].removeFrom(this.map)
      //   delete this.activeTracksbyDate[trackId]
      //   // const startendID = Object.keys(this.startend)[0],
      //   //   startendData = Object.values(this.startend)[0]
      //   // this.addStartEndByDate(startendID, startendData)
      // }

      if (annData.length >= 4) {
        if (this.markerStops) {
          // this.markerStops.removeFrom(this.map)
          this.CLEAR_CURRENT_ROW()
        }
        // добавляем трек на карту по клику на строку отчета
        this.addTrackByDatetoMap(trackId, trackData, annData, track)
      } else {
        // if (this.currentFlags) {
        //   this.map.removeLayer(this.currentFlags)
        // }
        if (this.activeTracksbyDate[this.trackId]) {
          // console.log('clear layer 1')
          this.activeTracksbyDate[this.trackId].clearLayers()
          delete this.activeTracksbyDate[this.trackId]
        } else {
          // console.log('clear layer 2')
          const id = Object.keys(this.activeTracksbyDate)[0]
          if (id) {
            this.activeTracksbyDate[id].clearLayers()
            // console.log('clear layer 3')
            delete this.activeTracksbyDate[id]
          }
        }
      }
    },

    coordinateForTrack(newVal) {
      this.currentCoordinateOnMap = newVal
    }
  },

  methods: {
    ...mapMutations('reports', [
      'SET_CURRENT_TABLE_DATA',
      'DIRECTIVE_ZOOM',
      'CLOSED_CALL_DRAW',
      'CLEAR_TREK'
    ]),
    ...mapMutations('map', ['CLEAR_CURRENT_ROW']),
    hideArrowsFromMap(val) {
      this.zoomActive = false
      this.DIRECTIVE_ZOOM(false)
      this.reporttable = false

      const trekSignature = document.querySelectorAll('.trek-signatures')
      const arrowsDirection = document.querySelectorAll(
        '.arrow-svg .arrow-svg-path'
      )

      arrowsDirection.forEach((item) => {
        if (val) {
          item.style.fill = 'rgba(255, 255, 255, 0)'
        } else {
          item.style.fill = '#F56C6C'
        }
      })
      trekSignature.forEach((item) => {
        if (val) {
          item.style.display = 'none'
        } else {
          item.style.display = 'block'
        }
      })
    },
    updateWeight(layer, weight) {
      layer.setStyle({ weight })
    },
    updateWeightRow(layer, weight) {
      layer.setStyle({ weight: weight * 2 })
    },
    closeTrackByRow() {
      const colorCompany =
        this.colorCompany.length && this.colorCompany[0].color

      this.trackByRowGroup.eachLayer((layer) => {
        this.map.removeLayer(layer)
      })
      this.trackByRowGroup.clearLayers()

      if (this.tracksToDraw) {
        this.tracksToDraw.eachLayer((layer) => {
          const { originalStyle } = layer
          if (originalStyle) {
            layer.setStyle(originalStyle)
          }
          if (colorCompany) {
            layer.setStyle({ color: colorCompany })
          }
        })
      }

      if (this.groupOrNormalReport === 'group') {
        this.hideArrowsFromMap(true)
      }
      // this.hideArrowsFromMap(true)

      // Remove the currentFlags layer
      this.map.removeLayer(this.currentFlags)
    },
    removeCurrentTrackFromMap(tableData) {
      this.SET_CURRENT_TABLE_DATA(tableData)
      if (this.tracksToDraw) {
        this.tracksToDraw.off('click', this.showTrackAnnotation, this)
        this.tracksToDraw.removeFrom(this.map)
      }
    },

    drawDirectives() {
      const combineTracks = this.combinePolylines.reduce(
        (acc, p) => [...acc, ...p.track],
        []
      )
      this.showDirectives({ ...this.polyline, track: combineTracks }, 'reports')
    },

    updatePolylines() {
      if (this.combinePolylines.length) {
        this.DIRECTIVE_ZOOM(true)
        this.lastDistance = 0
        this.zoomReports = true

        this.drawDirectives()

        this.map.on('moveend', () => {
          if (this.directiveZoom && this.zoomReports) {
            this.zoomActive = true

            // display more than one polyline track arrows
            this.drawDirectives()
          }
        })
      }
    },

    clearTrackFlags(val) {
      // remove start and end path markers from the map
      const trackId = Object.keys(val)[0]
      if (this.currentFlags && this.currentFlags.removeFrom) {
        this.currentFlags.removeFrom(this.map)
      }
      if (this.activeStartends[trackId]) {
        this.activeStartends[trackId].removeFrom(this.map)
      }
    },

    clearMarkerStops() {
      if (this.markerStops) {
        this.markerStops.removeFrom(this.map)
      }
    },

    showTrackAnnotation(
      target,
      id,
      coord,
      allTrack,
      trackPopup = this.tracksToDraw
    ) {
      if (!target) return
      if (this.tracksToDraw !== null) {
        this.tracksToDraw.unbindPopup()
      }

      let closestSegment = null
      let minDistance = Infinity

      // ищем ближайший отрезок от точки клика по треку
      allTrack.forEach((track) => {
        const { points } = track
        for (let i = 1; i < points.length; i++) {
          const point1 = L.latLng(points[i][1], points[i][2])
          const point2 = L.latLng(points[i - 1][1], points[i - 1][2])

          const distance = geometryutil.distanceSegment(
            this.map,
            coord,
            point1,
            point2
          )

          // Если текущий сегмент ближе, обновляем ближайший сегмент
          if (distance < minDistance) {
            minDistance = distance
            closestSegment = {
              timestamp1: points[i - 1][0],
              timestamp2: points[i][0],
              lat1: point1.lat,
              lng1: point1.lng,
              lat2: point2.lat,
              lng2: point2.lng
            }
          }
        }
      })

      const unitId = []
      if (target.target && target.target.options.unitId) {
        unitId.push({ id: target.target.options.unitId })
      }

      if (this.otechGeozone && this.otechGeozone.row._unit_id && !id) {
        unitId.push({ id: this.otechGeozone.row._unit_id })
      }

      if (id) {
        unitId.push({ id })
      }
      const data = {
        from: moment
          .unix(closestSegment.timestamp1)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        to: moment
          .unix(closestSegment.timestamp2)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        latlon: coord
      }

      trackPopup.unbindPopup()
      this.setTrackAnnotations(data, unitId)
        .then((res) => {
          let tooltipTable = ''
          res.columns
            .filter((k) => k.key.charAt(0) !== '_')
            .forEach((row) => {
              tooltipTable += `<tr class="border-b last_border-b-0"><td class="px-2 py-1 text-annotationColor font-semibold border-l-0 border-r-0 font-SourceSansPro text-base">${row.name}: </td>
            <td  style="word-break: break-word;"  class="px-2 py-1 text-darkblue font-bold border-l-0 border-r-0 text-sm ">${row.value}</td></tr>`
            })
          const latlng = res.columns.find((el) => el.key === '_marker_latlon')
          trackPopup
            .bindPopup(
              `<table class="table-auto m-2 border-collapse">
            <tbody>${tooltipTable}<tbody>`,
              {
                className: 'bg-white'
              }
            )
            .openPopup(coord || latlng.value)
        })
        .catch((err) => {
          this.$showError(err)
          if (trackPopup) {
            trackPopup.closePopup()
          }
        })
    },

    // Закрываем popup
    clearPopupsAnnotationInLayer() {
      this.annotationLayer.closePopup()
    },

    // показать аннотацию на отдельном слое
    showTrackAnnotationToLayer(
      target,
      id,
      coord,
      allTrack,
      trackPopup = this.tracksToDraw
    ) {

      if (!target) return
      if (trackPopup !== null) {
        this.annotationLayer.unbindPopup()
      }

      let closestSegment = null
      let minDistance = Infinity

      // ищем ближайший отрезок от точки клика по треку
      allTrack.forEach((track) => {
        const { points } = track
        for (let i = 1; i < points.length; i++) {
          const point1 = L.latLng(points[i][1], points[i][2])
          const point2 = L.latLng(points[i - 1][1], points[i - 1][2])

          const distance = geometryutil.distanceSegment(
            this.map,
            coord,
            point1,
            point2
          )

          // Если текущий сегмент ближе, обновляем ближайший сегмент
          if (distance < minDistance) {
            minDistance = distance
            closestSegment = {
              timestamp1: points[i - 1][0],
              timestamp2: points[i][0],
              lat1: point1.lat,
              lng1: point1.lng,
              lat2: point2.lat,
              lng2: point2.lng
            }
          }
        }
      })

      const unitId = []
      if (target.target && target.target.options.unitId) {
        unitId.push({ id: target.target.options.unitId })
      }

      if (this.otechGeozone && this.otechGeozone.row._unit_id && !id) {
        unitId.push({ id: this.otechGeozone.row._unit_id })
      }

      if (id) {
        unitId.push({ id })
      }
      const data = {
        from: moment
          .unix(closestSegment.timestamp1)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        to: moment
          .unix(closestSegment.timestamp2)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        latlon: coord
      }

      // trackPopup.unbindPopup()
      this.setTrackAnnotations(data, unitId)
        .then((res) => {
          let tooltipTable = ''
          res.columns
            .filter((k) => k.key.charAt(0) !== '_')
            .forEach((row) => {
              tooltipTable += `<tr class="border-b last_border-b-0"><td class="px-2 py-1 text-annotationColor font-semibold border-l-0 border-r-0 font-SourceSansPro text-base">${row.name}: </td>
            <td  style="word-break: break-word;"  class="px-2 py-1 text-darkblue font-bold border-l-0 border-r-0 text-sm ">${row.value}</td></tr>`
            })
          const latlng = res.columns.find((el) => el.key === '_marker_latlon')

          this.annotationLayer.bindPopup(
              `<table class="table-auto m-2 border-collapse">
            <tbody>${tooltipTable}<tbody>`,
              {
                className: 'bg-white'
              }
            )
            .openPopup(coord || latlng.value)
        })
        .catch((err) => {
          this.$showError(err)
          if (this.annotationLayer) {
            this.annotationLayer.closePopup()
          }
        })
    },

    setTrackAnnotations(date, unitId, isNewPostPoint = true) {
      // console.log(date, unitId, isNewPostPoint = true);
      let formData = {
        units: unitId,
        from: date
      }
      if (isNewPostPoint) {
        formData = {
          units: unitId,
          from: date.from,
          to: date.to,
          latlon: date.latlon
        }
      }
      return new Promise((resolve, reject) => {
        trackingApi.postPoint(
          { formData },
          (response) => {
            resolve(response.data)
          },
          (error) => {
            reject(error)
            this.$showError(error.response.data.message)
          }
        )
      })
    },

    hideTrack() {
      console.log('hide track ')
      this.tracksToDraw.removeFrom(this.map)
    },

    resetReports() {
      this.CLOSED_CALL_DRAW(true)
      if (this.timeArrows) {
        clearTimeout(this.timeArrows)
      }
      if (this.markerStops) {
        this.markerStops.removeFrom(this.map)
      }
      this.map.removeLayer(this.currentFlags)
      this.$store.commit('map/EYE_TRIGGER', 'tracks')
      if (this.activeTracksbyDate[this.trackId]) {
        this.activeTracksbyDate[this.trackId].forEach((track) => {
          track.removeFrom(this.map)
        })

        delete this.activeTracksbyDate[this.trackId]
      } else {
        const id = Object.keys(this.activeTracksbyDate)[0]
        if (id) {
          this.trackByRowGroup.eachLayer((layer) => {
            this.map.removeLayer(layer)
          })
          this.trackByRowGroup.clearLayers()

          // this.activeTracksbyDate[id].removeFrom(this.map)
          // delete this.activeTracksbyDate[id]
        }
      }

      this.zoomActive = false
      this.DIRECTIVE_ZOOM(false)
      this.reporttable = false
      let deleteTrek = false
      this.$emit('closeReportTable')
      const unitId =
        this.tableCurrentId && this.tableCurrentId.unit_id
          ? this.tableCurrentId.unit_id
          : this.tableCurrentId && this.tableCurrentId.units
          ? this.tableCurrentId.units[0].id
          : ''

      this.polylines.forEach((val, key) => {
        if (this.tableCurrentId && unitId.indexOf(val.unit_id) === -1) {
          deleteTrek = true
        } else if (
          val.to !== this.tableCurrentId.to &&
          val.from !== this.tableCurrentId.from
        ) {
          deleteTrek = true
        }

        if (
          this.tableCurrentId &&
          this.tableCurrentId.columns &&
          this.polylines.length - 1 === key
        ) {
          this.polylines.splice(key, 1)
        } else if (
          this.polylines.length - 1 === key &&
          this.tableCurrentId &&
          unitId.indexOf(val.unit_id) !== -1 &&
          val.to === this.tableCurrentId.to &&
          val.from === this.tableCurrentId.from
        ) {
          this.polylines.splice(key, 1)
        }
      })

      if (
        (!this.polylines.length || deleteTrek) &&
        this.tracksToDraw &&
        this.tableCurrentId &&
        this.closeTrek
      ) {
        this.tracksToDrawAll.forEach((vall, key) => {
          // const startDate = new Date(this.tableCurrentId.from)
          // const currentDay = startDate.getDate()
          // const currentMonth = startDate.getUTCMonth() + 1
          const trackTime = new Date(1970, 0, 1)
          trackTime.setSeconds(this.tracksToDrawAll[key].time)
          if (!this.tableCurrentId.unit_id) {
            this.tableCurrentId.unit_id = this.tableCurrentId.units[0].id
          }
          if (
            this.tableCurrentId.unit_id.indexOf(vall.track.options.unitId) !==
            -1
          ) {
            this.tracksToDraw = vall.track
            if (this.tracksToDraw) {
              this.tracksToDraw.off('click', this.showTrackAnnotation, this)
              this.tracksToDrawAll.splice(key, 1)
            }
          }
        })

        this.tracksToDraw.removeFrom(this.map)

        if (this.tracksToDrawAll.length) {
          this.tracksToDraw = this.tracksToDrawAll[0].track
        } else {
          this.$store.dispatch('units/DEL_UNITS_GROUP')
          this.tracksToDraw = null
          this.polylines = []
          // const canvas = document.querySelectorAll(
          //   '.leaflet-overlay-pane canvas'
          // )
          // if (navigator.userAgent.indexOf('Firefox') === -1) {
          //   canvas[0].style.zIndex = '101'
          //   canvas[1] && canvas[1].style.zIndex === '100'
          // } else {
          //   canvas[1].style.zIndex = '101'
          //   canvas[1] && canvas[0].style.zIndex === '100'
          // }
          this.SET_CURRENT_TABLE_DATA(null)
        }
      }
      this.clearTrackStyles()
      this.$store.commit('map/TOGGLE_TREK_INFO', false)
      this.isReportClosed = true
      setTimeout(() => {
        const zoomLvl = this.map.getZoom()
        this.map.setZoom(zoomLvl)
        this.polylines = []
        this.isReportClosed = false
      }, 100)
    },
    clearTrackStyles() {
      if (this.borderLayersGroup && this.shadowLayersGroup) {
        if (this.borderLayersGroup.getLayers().length > 0) {
          this.borderLayersGroup.eachLayer((layer) =>
            layer.removeFrom(this.map)
          )
        }

        if (this.shadowLayersGroup.getLayers().length > 0) {
          this.shadowLayersGroup.eachLayer((layer) =>
            layer.removeFrom(this.map)
          )
        }
      }
      this.$store.commit('monitoring/clearMonitoringTrack')
    },
    showTrack(tracksArr, defaultStyle = { color: '#e31e24' }) {
      return tracksArr.map((track) => {
        const points = track.points.map((p) => [p[1], p[2]])
        const { color } = defaultStyle

        const time = Object.fromEntries(
          track.points.map((p) => [p[0], L.latLng(p[1], p[2])])
        )
        return L.polyline(points, { color, weight: 10, time })
      })
    },
    addStartEndByDate(startendId, startendData) {
      // remove stops markers before add track markers
      this.clearMarkerStops()

      if (!this.currentFlags.length) {
        this.map.removeLayer(this.currentFlags)
      }
      const myStart = startendData[0]
      const myEnd = startendData[1]
      const startIcon = L.icon({
        iconUrl: '/static/start.svg',
        iconSize: [26, 36],
        iconAnchor: [13, 36]
      })
      const endIcon = L.icon({
        iconUrl: '/static/end.svg',
        iconSize: [26, 36],
        iconAnchor: [13, 36]
      })
      const startMarker = L.marker(myStart, {
        title: 'Start',
        alt: 'Start',
        icon: startIcon,
        draggable: false
      })
      const endMarker = L.marker(myEnd, {
        title: 'End',
        alt: 'End',
        icon: endIcon,
        draggable: false
      })
      // console.log('boom', myStart, myEnd)

      this.currentFlags = L.featureGroup([endMarker, startMarker])
      this.currentFlags.addTo(this.map)
      this.activeStartends = {
        ...this.activeStartends,
        [startendId]: this.currentFlags
      }
      this.activeStartends[startendId].addTo(this.map)
      this.map.eachLayer((l) => {
        if (l.getTooltip()) {
          const tooltip = l.getTooltip()
          if (this.otechGeozone.row.geo_name === tooltip._content) {
            l.unbindTooltip().bindTooltip(tooltip, {}).openTooltip()
          }
        }
      })
    },

    addTrackByDatetoMap(trackId, dates, annData, track) {
      this.trackByRowGroup.eachLayer((l) => l.removeFrom(this.map))

      if (this.tracksToDraw) {
        this.tracksToDraw.eachLayer((layer) => {
          layer.setStyle({
            color: 'gray'
          })
        })
      }
      this.borderLayersGroup.eachLayer((layer) => layer.removeFrom(this.map))
      this.shadowLayersGroup.eachLayer((layer) => layer.removeFrom(this.map))
      const trackPoints = dates.map((h) => [h[1], h[2]])
      const polyline = L.polyline(trackPoints, {
        color: '#1727b8',
        time: dates.map((p) => {
          return {
            [p[0]]: L.latLng(p[1], p[2])
          }
        })
      })

      const trackLayers = this.showTrack(track, this.styles)
      for (let i = 0; i < trackLayers.length; i++) {
        const layer = trackLayers[i]
        layer.setStyle({
          weight: this.currentWeights * 2
        })

        this.trackByRowGroup.addLayer(layer)
        this.map.addLayer(layer)
      }

      this.activeTracksbyDate = {
        ...this.activeTracksbyDate,
        [trackId]: trackLayers
      }
      this.markerData = {
        ...this.markerData,
        [trackId]: {
          dates: trackPoints,
          annData
        }
      }

      this.trackByRowGroup.on('click', (e) => {
        this.showTrackAnnotation(
          e.layer,
          this.unitIdTracksToDraw,
          e.latlng,
          track
        )
      })

      const paddingOptions = {
        paddingTopLeft: [0, 0],
        paddingBottomRight: [0, 310]
      }
      if (!this.isMarkerCentered) {
        this.map.fitBounds(polyline.getBounds(), paddingOptions)
      }
    },

    addMarkerBydateToMap(markerId, markerData) {
      let iconUrl
      let tooltipTable = ''
      let markerLatLng
      if (markerData) {
        if (markerData.notification_type) {
          markerLatLng = markerData._marker_latlon_notifications
          const stopType = markerData._type_notifications
          iconUrl = `/static/notification_markers_front/${stopType}.svg`
        } else if (markerData._marker_latlon_stops) {
          iconUrl = '/static/stops_front/' + 'stops' + '.svg'
          markerLatLng = markerData._marker_latlon_stops
        } else if (markerData._marker_latlon_thefts) {
          iconUrl = '/static/stops_front/' + 'thefts' + '.svg'
          markerLatLng = markerData._marker_latlon_thefts
        } else if (markerData._marker_latlon_stays) {
          iconUrl = '/static/stops_front/' + 'stays' + '.svg'
          markerLatLng = markerData._marker_latlon_stays
        } else if (markerData._marker_latlon_journal) {
          iconUrl = require('../assets/icons/row-marker.svg')
          markerLatLng = markerData._marker_latlon_journal
        } else if (markerData._marker_latlon_photos) {
          iconUrl = '/static/stops_front/' + 'photos' + '.svg'
          markerLatLng = markerData._marker_latlon_photos
        } else if (markerData._marker_latlon_fillings) {
          iconUrl = '/static/stops_front/' + 'fillings' + '.svg'
          markerLatLng = markerData._marker_latlon_fillings
        } else if (markerData._marker_latlon_events) {
          markerLatLng = markerData._marker_latlon_events
          iconUrl = `/static/events_markers_front/${markerData._type_events}.svg`
        } else if (
          markerData &&
          markerData._marker_latlon_chronology &&
          markerData._marker_latlon_chronology !== '-'
        ) {
          iconUrl = `/static/stops_front/${markerData._type_chronology}.svg`
          markerLatLng = markerData._marker_latlon_chronology
        } else if (markerData._marker_latlon_digital_sensors) {
          iconUrl = '/static/stops_front/' + 'fillings' + '.svg'
          markerLatLng = markerData._marker_latlon_digital_sensors
        } else if (markerData._marker_latlon_messages) {
          iconUrl = require('../assets/icons/row-marker.svg')
          markerLatLng = markerData._marker_latlon_messages
        } else if (markerData._marker_latlon_driving_style) {
          iconUrl = `/static/events_markers_front/sharp_braking.svg`
          // iconUrl = '/static/events_markers_front/driving_style.svg'
          markerLatLng = markerData._marker_latlon_driving_style
        }
        tooltipTable += `<div class='mt-4'> `
        // FIN FOTO ------------
        if (markerData._marker_latlon_photos) {
          this.columnTable.forEach((column) => {
            if (markerData[column.key]) {
              tooltipTable += `
            ${
              column.name !== 'Photo' &&
              column.name !== '№' &&
              column.name !== 'Фото'
                ? `
                <div class="  flex mb-4 mr-4 text-left">
                  <span  style=' width:80px ' class='mr-4  text-annotationColor font-semibold font-SourceSansPro text-base '> ${
                    column.name === 'Дата и время'
                      ? 'Дата:'
                      : column.name === 'Имя'
                      ? 'Объект:'
                      : column.name === 'Адрес'
                      ? 'Адрес:'
                      : column.name
                  }
                  </span>
                  <div class=' text-darkblue font-bold  text-sm'  style='  width:250px' > ${
                    markerData[column.key]
                  }</div>
                </div>
            `
                : ''
            }

            `
            }
          })
          const imgReport = `
            <div style="display:flex;align-items:center;justify-content:center">
              <img download src="${`${document.location.href}/api_v1/${markerData._photo_url}`}" style="width:330px; border-radius:6px;">
            </div>`

          const icon = L.icon({
            iconUrl
            // iconSize: markerData._marker_latlon_journal ? [26, 36] : [45, 61],
          })
          this.markerStops = L.marker(markerLatLng, {
            icon,
            draggable: false
          }).bindPopup(
            ` ${tooltipTable}
            ${imgReport}
        `,
            {
              className: 'photosPopup',
              offset: L.point(0, -22)
            }
          )
          this.markerStops.on('add', (event) => {
            event.target.openPopup()
          })
        } else if (markerData._marker_latlon_events) {
          // popup когда кликаем на событие в отчете
          const filterArr = this.columnTable.filter((column) => {
            return (
              markerData[column.key] !== '-' &&
              markerData[column.key] !== 0 &&
              markerData[column.key] !== ''
            )
          })

          filterArr.forEach((column) => {
            tooltipTable += `
            <tr class="border-b last_border-b-0">
              <td class="px-2 py-1 text-annotationColor font-semibold border-l-0 border-r-0 font-SourceSansPro text-base">
                ${column.name}: </td>
              <td style="word-break: break-word;"  class="px-2 py-1 text-darkblue font-bold border-l-0 border-r-0 text-sm ">
              ${markerData[column.key]}</td>
            </tr>
          `
          })

          const icon = L.divIcon({
            iconUrl,
            iconSize: markerData._marker_latlon_journal ? [26, 36] : [26, 36],
            // iconAnchor: markerData._marker_latlon_journal ? [13, 36] : '',
            html: this.eventsCreateMarkerIconHtml(iconUrl),
            popupAnchor: [12, 0],
            className: 'skif-event-markerw'
          })
          this.markerStops = L.marker(markerLatLng, {
            icon,
            draggable: false
          }).bindPopup(
            ` <table style="overflow:auto"  class="table-auto file  m-2 border-collapse">
          <tbody>${tooltipTable}<tbody>
          </table>`,
            {
              className: 'stopsPopup opop '
              // offset: L.point(0, -22),
            }
          )
          this.markerStops.on('add', (event) => {
            event.target.openPopup()
          })
        } else {
          this.columnTable.forEach((column) => {
            if (markerData[column.key]) {
              tooltipTable += `
              <tr class="border-b last_border-b-0">
                <td class="px-2 py-1 text-annotationColor font-semibold border-l-0 border-r-0 font-SourceSansPro text-base">
                  ${column.name}: </td>
                <td  style="word-break: break-word;" class="px-2 py-1 text-darkblue font-bold border-l-0 border-r-0 text-sm">
                ${markerData[column.key]}</td>
              </tr>
            `
            }
          })
          const icon = L.divIcon({
            // iconUrl,
            // iconAnchor: [10, 10],
            iconSize: [45, 61],
            iconAnchor: markerData._marker_latlon_driving_style ? [13, 36] : '',
            // html: this.eventsCreateMarkerIconHtml(iconUrl),
            html: this.eventsCreateMarkerIconHtml(iconUrl),
            popupAnchor: markerData._marker_latlon_driving_style
              ? [6, 6]
              : [0, 10],
            className: ''
            // className: 'hidden-square'
          })
          const isShowAnnotationOnClick = JSON.parse(
            localStorage.getItem('isShowAnnotationOnClick')
          )
          if (isShowAnnotationOnClick) {
            this.markerStops = L.marker(markerLatLng, {
              icon,
              draggable: false
            }).bindPopup(
              ` <table style="overflow:auto"  class="table-auto file  m-2 border-collapse">
            <tbody>${tooltipTable}<tbody>
          </table>`,
              {
                className: 'stopsPopup opop',
                offset: L.point(0, -30)
              }
            )
            this.markerStops.on('add', (event) => {
              event.target.openPopup()
            })
          } else {
            this.markerStops = L.marker(markerLatLng, {
              icon,
              draggable: false
            })
          }
        }
      }
      this.activeMarkers = {
        ...this.activeMarkers,
        [markerId]: this.markerStops
      }
      if (this.isAllStops === 'all') {
        this.$store.dispatch(
          'map/SET_SHOW_MARKER_FROM_CLUSTER',
          this.markerStops
        )
        // delete this.activeMarkers[markerId]
      } else {
        this.markerStops.addTo(this.map)
        this.currentMarker = this.markerStops
        this.isMarkerCentered = true
        this.map.setView(this.markerStops.getLatLng(), this.zoom)
        setTimeout(() => {
          // - timout to let the track if exist get centred
          this.isMarkerCentered = false
        }, 1000)
      }
    }
  }
}
